import { Link } from 'react-router-dom';

import styles from './styles.module.css';

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <div className={styles.footer}>
            <div className="footer-rights" style={{textAlign: 'center'}}>
                САЙТ РАЗРАБОТАН КОМПАНИЕЙ <Link to="/"> ULTRANOUT.RU </Link>{' '}
                2014-{currentYear}. НАША ЦЕЛЬ - ДОВОЛЬНЫЙ ПОКУПАТЕЛЬ
            </div>
            <div className={styles.files}>
                <a href="/privacy-policy.pdf" download>
                    Политика конфиденциальности
                </a>
                <a href="/offer.pdf" download>
                    Публичная оферта
                </a>
            </div>
            <div className="footer-payment-methods">
                <img
                    style={{transform: 'scale(0.8)'}}
                    src={
                        `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2025/01/paymentlogos-e1736887916249.png`
                    }
                    alt=""
                />
            </div>
        </div>
    );
}

export default Footer;
