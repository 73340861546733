import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTableCells} from "@fortawesome/free-solid-svg-icons";

export const CatalogueBreadcrumbs = ({currentAmount, viewMode, onChangeShowAmount, onChangeViewMode}) => {
    return(
        <div
            className="products-controls"
            style={{
                display: "flex",
                flexDirection: "row",
                paddingRight: '10px'
            }}
        >
            <div className='products-controls_per-page'>
                <label className="per-page-title">
                    Показать:
                </label>
                <button value={12} onClick={onChangeShowAmount} className={currentAmount === '12' ? 'button-wrapper_clicked' : 'button-wrapper'}>
                    {' '}12 /
                </button>
                <button value={24} onClick={onChangeShowAmount} className={currentAmount === '24' ? 'button-wrapper_clicked' : 'button-wrapper'}>
                    24 /
                </button>
                <button value={36} onClick={onChangeShowAmount} className={currentAmount === '36' ? 'button-wrapper_clicked' : 'button-wrapper'}>
                    36 /
                </button>
                <button value="all" onClick={onChangeShowAmount} className={currentAmount === "all" ? 'button-wrapper_clicked' : 'button-wrapper'}>
                    Все
                </button>
            </div>
            <OverlayTrigger overlay={
                <Tooltip>
                    Показать списком
                </Tooltip>
            } placement={'top'}>
                <button
                    style={{
                        marginLeft: '5px',
                        border: "none",
                        backgroundColor: 'white'
                    }}
                    className={viewMode === 'rows' ? 'button-wrapper_clicked' : 'button-wrapper'}
                    value='rows'
                    onClick={() => onChangeViewMode('rows')}
                >
                    <FontAwesomeIcon icon={faBars} size='xl'/>
                </button>
            </OverlayTrigger>
            <OverlayTrigger overlay={
                <Tooltip>
                    Показать таблицей
                </Tooltip>
            } placement={'top'}>
                <button
                    style={{
                        marginLeft: '5px',
                        border: "none",
                        backgroundColor: 'white'
                    }}
                    className={viewMode === 'table' ? 'button-wrapper_clicked' : 'button-wrapper'}
                    onClick={() => onChangeViewMode('table')}
                >
                    <FontAwesomeIcon icon={faTableCells} size='xl'/>
                </button>
            </OverlayTrigger>
        </div>
    )
}
