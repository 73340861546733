import '../../components/page-title/page-title.css';
import PageTitle from '../../components/page-title/pageTitle';
import { useEffect, useState } from 'react';
import ProductCard from '../../components/product-card';
import FiltersSidebar from '../../components/filters-sidebar';
import '../../styles/button.css';
import './Catalogue.css';
import handleCartClick from '../../helpers/handleCartClick';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Badge, Button, CloseButton, Offcanvas, Spinner } from 'react-bootstrap';
import SortBy from '../../components/sort-by/sortBy';
import { useDebounce } from '../../helpers/hooks/useDebounce';
import { CatalogueBreadcrumbs } from '../../components/catalogue-breadcrumbs';
import { handleWishlistClick } from '../../helpers/handleWishlistClick';
import { useProducts } from '../../helpers/hooks/useProducts';
import { useMinMaxPrice } from '../../helpers/hooks/useMinMaxPrice';
import ReactPaginate from 'react-paginate';
import { handleCompareClick } from '../../helpers/handleCompareClick';
import { useMediaQuery } from '../../helpers/hooks/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { MobileCatalogueBreadcrumbs } from '../../mobile-components/mobile-catalogue-breadcrumbs';
import { useMinMaxWeight } from '../../helpers/hooks/useMinMaxWeight';

import styles from './catalogue.module.css'
import { clsx } from 'clsx';

//Каталог
const Catalogue = () => {
    const params = useParams();
    const matches = useMediaQuery('(min-width: 1000px)');
    const [searchParams, setSearchParams] = useSearchParams();
    const [category, setCategory] = useState({});
    const categoryMap = {
        'igrovoj-noutbuk': 'Игровой ноутбук',
        'rabochaja-stancija': 'Рабочая станция',
        'transformer': 'Трансформер',
        'ultrabuk': 'Ультрабук'
    }
    const [parameters, setParameters] = useState({
        per_page: 12,
        page: 1,
        orderBy: 'salePrice,ASC',
        searchString: searchParams.get('searchString')
    });
    const [viewMode, setViewMode] = useState('rows');
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [wishlist, setWishlist] = useState(
        JSON.parse(localStorage.getItem('wishlist')),
    );
    const [compare, setCompare] = useState(
        JSON.parse(localStorage.getItem('compare')),
    );

    const [selectedFilters, setSelectedFilters] = useState([]);
    const debouncedFilters = useDebounce(selectedFilters, 1000);
    const [isLoading, setIsLoading] = useState(true);
    const { products, productsTotal } = useProducts(
        setIsLoading,
        category,
        parameters,
        debouncedFilters,
    );

    const [prices, setPrices] = useMinMaxPrice();

    const [weight, setWeight] = useMinMaxWeight();

    const [showMobileFilters, setShowMobileFilters] = useState(false);

    useEffect(() => {
        setCategory(params.category);
    }, [params]);

    useEffect(() => {
        setParameters({
            ...parameters,
            searchString: searchParams.get('searchString'),
            // orderBy: searchParams.get('orderBy')
        })
    }, [searchParams])

    // Это конечно кошмар, иф на ифе, надо переписать
    const handleFilterChange = (filterName, value) => {
        // console.log(...value);
        const ARR_ITEM_NOT_FOUND = -1;
        const newFilters = selectedFilters.slice();
        const itemToChangeIndex = newFilters.findIndex(
            (item) => item.name === filterName,
        );
        if (itemToChangeIndex === ARR_ITEM_NOT_FOUND) {
            if (filterName === 'Цена' || filterName === 'Вес') {
                newFilters.push({ name: filterName, values: [...value] });
                // console.log(newFilters);
                return setSelectedFilters(newFilters);
            }
            newFilters.push({
                name: filterName,
                values: [value],
            });
        } else {
            if (filterName === 'Цена' || filterName === 'Вес') {
                newFilters[itemToChangeIndex].values = value;
                // console.log(newFilters);
                return setSelectedFilters(newFilters);
            }
            // if (filterName === 'Доступность на складе') {
            //     newFilters[itemToChangeIndex].values = [value];
            //     return setSelectedFilters(newFilters);
            // }
            const valueToChangeIndex = newFilters[
                itemToChangeIndex
            ].values.findIndex((item) => item.toString() === value);
            if (valueToChangeIndex === ARR_ITEM_NOT_FOUND) {
                newFilters[itemToChangeIndex].values.push(value);
            } else {
                newFilters[itemToChangeIndex].values.splice(
                    valueToChangeIndex,
                    1,
                );
            }
        }
        setSelectedFilters(newFilters);
    };

    const handleChangeViewMode = (mode) => {
        setViewMode(mode);
    };

    const handleShowAmountClick = (event) => {
        setParameters({
            ...parameters,
            per_page: event.target.value,
        });
    };

    const handlePageChange = (event) => {
        setParameters({
            ...parameters,
            page: event.selected + 1,
        });
        window.scrollTo(0, 0);
    };

    const handleSortChange = (event) => {
        setParameters({
            ...parameters,
            orderBy: event.target.value,
        });
    };

    return (
        <div className="catalogue">
            <PageTitle title="Каталог" bottomText="Главная / Каталог" />
            <div
                className="catalogue-content"
                style={{
                    display: 'flex',
                    flexDirection: matches ? 'row' : 'column',
                    marginLeft: '10px',
                }}>
                <div
                    className="catalogue-content_sort-filters"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 5px 5px 5px',
                        maxWidth: matches ? '25%' : '95%',
                    }}>
                    <SortBy onSortChange={handleSortChange} parameters={parameters}/>
                    {matches ? (
                        <div className="catalogue-content_filters">
                            <h4>Фильтры</h4>
                            <FiltersSidebar
                                defaultPrices={prices}
                                defaultWeight={weight}
                                products={products}
                                onFilterChange={handleFilterChange}
                                selectedFilters={selectedFilters}
                            />
                        </div>
                    ) : (
                        <div className="catalogue-content_filters">
                            <div
                                aria-label="Показать фильтры в мобильной версии"
                                className={styles.mobileFilters}
                                onClick={() => setShowMobileFilters(true)}>
                                <FontAwesomeIcon icon={faBars} />
                                <label style={{ marginLeft: '0.375rem' }}>
                                    {' '}
                                    Показать фильтры
                                </label>
                            </div>
                            <Offcanvas
                                show={showMobileFilters}
                                onHide={() => setShowMobileFilters(false)}
                                className="mobile-header_navbar">
                                <Offcanvas.Header closeButton>
                                    <Offcanvas.Title>Фильтры</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body className="mobile-filters">
                                    <div className={styles.confirmButtonWrapper}>
                                        <Button className={clsx('button_main_color', styles.confirmButton)} onClick={() => setShowMobileFilters(false)}>Применить</Button>
                                    </div>
                                    <FiltersSidebar
                                        defaultPrices={prices}
                                        defaultWeight={weight}
                                        products={products}
                                        category={category}
                                        onFilterChange={handleFilterChange}
                                        selectedFilters={selectedFilters}
                                    />
                                </Offcanvas.Body>
                            </Offcanvas>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'space-between',
                        width: matches ? '80%' : '90%',
                        marginLeft: '10px',
                    }}>
                    <>
                        {selectedFilters.length !== 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    padding: '0.2rem',
                                    flexWrap: 'wrap',
                                }}>
                                <Badge
                                    bg="ultranout-selected-filters"
                                    onClick={() => {
                                        setSelectedFilters([]);
                                        setPrices(prices.slice());
                                        setWeight(weight.slice());
                                    }}>
                                    Сбросить фильтры
                                </Badge>
                                {selectedFilters.map((element) =>
                                    (element.values.length !== 0 &&
                                        element.name === 'Цена') ||
                                    element.name === 'Вес' ? (
                                        <Badge
                                            key={element.name}
                                            bg="ultranout-selected-filters"
                                            onClick={() => {
                                                setSelectedFilters(
                                                    selectedFilters.filter(
                                                        (catalogueFilter) =>
                                                            catalogueFilter.name !==
                                                            element.name,
                                                    ),
                                                );
                                                element.name === 'Цена'
                                                    ? setPrices(prices.slice())
                                                    : setWeight(weight.slice());
                                            }}>
                                            {element.name}: от{' '}
                                            {element.values[0]} до{' '}
                                            {element.values[1]}
                                            <CloseButton
                                                style={{
                                                    fontSize: '0.6rem',
                                                }}
                                            />
                                        </Badge>
                                    ) : (
                                        element.values.map((value) => (
                                            <Badge
                                                key={value}
                                                bg="ultranout-selected-filters"
                                                onClick={() =>
                                                  element.name !== 'Доступность на складе' ?
                                                    handleFilterChange(
                                                        element.name,
                                                        value,
                                                    ) : setSelectedFilters(
                                                      selectedFilters.filter(
                                                        (catalogueFilter) =>
                                                          catalogueFilter.name !==
                                                          element.name,
                                                      ),
                                                    )
                                                }>
                                                {value}
                                                <CloseButton
                                                    style={{
                                                        fontSize: '0.6rem',
                                                    }}
                                                />
                                            </Badge>
                                        ))
                                    ),
                                )}
                            </div>
                        )}
                    </>
                    <div
                        className="catalogue-breadcrumbs"
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                        <label
                            style={{
                                fontSize: '14px',
                            }}>
                            <Link
                                style={{
                                    textDecoration: 'none',
                                    color: 'black',
                                }}
                                to="/">
                                Главная
                            </Link>{' '}
                            /
                            <strong>
                                {' '}
                                {category ? categoryMap[category] : 'Каталог'}{' '}
                            </strong>
                        </label>
                        {matches ? (
                            <CatalogueBreadcrumbs
                                currentAmount={parameters.per_page}
                                viewMode={viewMode}
                                onChangeShowAmount={handleShowAmountClick}
                                onChangeViewMode={handleChangeViewMode}
                            />
                        ) : (
                            <MobileCatalogueBreadcrumbs
                                perPage={parameters.per_page}
                                page={parameters.page}
                                total={productsTotal}
                            />
                        )}
                    </div>
                    <div
                        className="product-cards-wrapper"
                        style={{
                            display: 'flex',
                            flexDirection: viewMode ? 'row' : 'column',
                            flexWrap: 'wrap',
                            alignItems: 'stretch',
                        }}>
                        {!isLoading ? (
                            products.length > 0 ? (
                                products.map((product, index) => (
                                    <div
                                        key={index}
                                        className="product-card_wrapper"
                                        style={{
                                            width:
                                                viewMode === 'table'
                                                    ? '33.3%'
                                                    : '100%',
                                        }}>
                                        <ProductCard
                                            product={product}
                                            verticalMode={
                                                viewMode === 'table' || !matches
                                            }
                                            onWishlistClick={() =>
                                                handleWishlistClick(
                                                    product,
                                                    wishlist,
                                                    setWishlist,
                                                )
                                            }
                                            onCartClick={() =>
                                                handleCartClick(
                                                    product,
                                                    cart,
                                                    setCart,
                                                )
                                            }
                                            isInWishlist={
                                                !wishlist.some(
                                                    (wishlistProduct) =>
                                                        wishlistProduct.id ===
                                                        product.id,
                                                )
                                            }
                                            isInCart={
                                                !cart.some(
                                                    (cartProduct) =>
                                                        cartProduct.id ===
                                                        product.id,
                                                )
                                            }
                                            isInCompare={
                                                !compare.some(
                                                    (compareProduct) =>
                                                        compareProduct.id ===
                                                        product.id,
                                                )
                                            }
                                            onCompareClick={() =>
                                                handleCompareClick(
                                                    product,
                                                    compare,
                                                    setCompare,
                                                )
                                            }
                                        />
                                    </div>
                                ))
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', margin: '10px', gap: '10px', color: 'white', backgroundColor: 'rgb(224 178 82)', width: '100%' }}>
                                    <FontAwesomeIcon icon={faCircleExclamation} />
                                    <p style={{marginTop: '1rem'}}>
                                        Товаров, соответствующих вашему запросу, не обнаружено.
                                    </p>
                                </div>
                            )
                        ) : (
                            <div style={{ padding: '5px' }}>
                                <Spinner
                                    animation="border"
                                    role="status-products"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ReactPaginate
                breakLabel="..."
                pageCount={Math.ceil(productsTotal / parameters.per_page)}
                nextLabel=">"
                onPageChange={handlePageChange}
                pageRangeDisplayed={5}
                previousLabel="<"
                containerClassName="pagination"
                pageClassName="pagination_item"
                previousLinkClassName="pagination_link"
                nextLinkClassName="pagination_link"
                previousClassName="pagination_prev pagination_item"
                nextClassName="pagination_next pagination_item"
                activeClassName="pagination_active pagination_item"
            />
        </div>
    );
};

export default Catalogue;
