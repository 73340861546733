//Главная страница

import YandexMap from '../../components/yandex-map/yandexMap';
import styles from './main-page.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '../../helpers/hooks/useMediaQuery';
import MainPageSearchMobile from '../../components/search-mobile';
import { Button } from 'react-bootstrap';
import { clsx } from 'clsx';
import { useEffect } from 'react';
import { PhoneLink } from '../../components/phone_lev_template';
import { EmailLink } from '../../components/email-link';

const staticImagesTop = [
    {
        link: '/catalogue/igrovoj-noutbuk',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2022/02/ultra-14.02.22-01-1024x491.png`,
        alt: 'Игровые ноутбуки',
    },
    {
        link: '/catalogue/rabochaja-stancija',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2022/02/ultra-14.02.22-02-1024x491.png`,
        alt: 'Рабочие станции',
    },
];

const staticImagesBottom = [
    {
        link: '/catalogue/ultrabuk',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2022/02/ultra-14.02.22-04-1024x750.png`,
        alt: 'Ультрабуки',
    },
    {
        link: '/catalogue/transformer',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2022/02/ultra-14.02.22-03-1024x750.png`,
        alt: 'Ноутбуки-трансформеры',
    },
    {
        link: '/catalogue/?orderBy=createdAt,DESC',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2022/02/ultra-14.02.22-05-1024x750.png`,
        alt: 'Новинки',
    },
];

const serviceIcons = [
    {
        link: '/contactUs',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2024/09/ikonki-01_01.png`,
        alt: '',
    },
    {
        link: '/options',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2024/09/ikonki-01_02.png`,
        alt: '',
    },
    {
        link: '/storeRules',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2024/09/ikonki-01_03.png`,
        alt: '',
    },
    {
        link: '/warranty',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2024/09/ikonki-01_04.png`,
        alt: '',
    },
    {
        link: '/deliveryMethods',
        src: `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2024/09/ikonki-01_05.png`,
        alt: '',
    },
];

const MainPage = () => {
    const matches = useMediaQuery('(min-width: 1000px)');
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('cart_id') && !matches) {
            window.YaAuthSuggest.init(
                {
                    client_id: 'cd3c2fc7e0db4356a56de4ec36bcb60a',
                    response_type: 'token',
                    redirect_uri: `${process.env.REACT_APP_PUBLIC_URL}/yandex-oauth`,
                },
                `${process.env.REACT_APP_PUBLIC_URL}`,
                {
                    view: 'button',
                    parentId: 'mobileContainer',
                    buttonSize: 'm',
                    buttonView: 'main',
                    buttonTheme: 'light',
                    buttonBorderRadius: '0',
                    buttonIcon: 'ya',
                },
            )
                .then(({ handler }) => handler())
                .then((data) => {
                    localStorage.setItem('cart_id', data.access_token);
                    navigate(0);
                })
                .catch((error) => console.log('Обработка ошибки', error));
        }
    }, [matches]);

    return (
        <div className={styles.mainPage}>
            <div className={styles.mobileTop}>
                <MainPageSearchMobile />
                <div id={'mobileContainer'} style={{
                    display: localStorage.getItem('cart_id') ? 'none' : 'initial',
                    alignSelf: 'center',
                    width: '95%',
                }}>

                </div>
                <Link to={'/catalogue'} className={styles.mobileLink}>
                    <Button
                        className={clsx(styles.mobileButton, 'button_main_color')}>
                        Перейти в каталог
                    </Button>
                </Link>
            </div>
            {matches && (
                <div className={styles.serviceIcons}>
                    {serviceIcons.map((icon) => (
                        <Link to={icon.link}>
                            <img
                                className={styles.serviceIconsItem}
                                src={icon.src}
                                alt={icon.alt}
                            />
                        </Link>
                    ))}
                </div>
            )}
            <div className={styles.laptopPictures}>
                <div className={styles.topRow}>
                    {staticImagesTop.map((image) => (
                        <Link to={image.link}>
                            <img
                                className={styles.image}
                                src={image.src}
                                alt={image.alt}
                            />
                        </Link>
                    ))}
                </div>
                <div className={styles.bottomRow}>
                    {staticImagesBottom.map((image) => (
                        <Link to={image.link}>
                            <img
                                className={styles.image}
                                src={image.src}
                                alt={image.alt}
                            />
                        </Link>
                    ))}
                </div>
            </div>
            {!matches && (
                <div className={styles.serviceIcons}>
                    {serviceIcons.map((icon) => (
                        <Link to={icon.link}>
                            <img
                                className={styles.serviceIconsItem}
                                src={icon.src}
                                alt={icon.alt}
                            />
                        </Link>
                    ))}
                </div>
            )}
            <div className={styles.bottomBlock}>
                <YandexMap />
                <div
                    className='wpb_wrapper'
                    style={{
                        width: matches ? '30%' : '100%',
                        paddingLeft: '10px',
                    }}>
                    <div className='wpb_text_column wpb_content_element'>
                        <div className='wpb_wrapper'>
                            <p style={{ color: '#777777' }}>
                                <strong>
                                    Если у Вас есть вопросы – свяжитесь с нами,
                                    мы всегда рады помочь!
                                    <br />
                                    С 10:00 до 21:00
                                    <br />
                                    Телефон: <PhoneLink />
                                    <br />
                                    Email: <EmailLink />
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div className='wpb_text_column wpb_content_element'>
                        <div className='wpb_wrapper'>
                            <p>
                                Самовывоз УЛЬТРА-НОУТ:
                                <br />
                                ВКЦ “Савеловский” , Москва, ул. Сущёвский Вал, 5с1А – 200 метров от метро ” Савеловская
                                “. Павильон ” F52″ в ТЦ «Савеловский» – Время работы: с 11 утра до 20-30, ежедневно.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainPage;
